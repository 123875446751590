<template>
    <div class="slide2" id="areyouwithus">
        <section class="home_are-with-us">
            <div class="home_are-with-us_mouth_slider">
                <img class="home_are-with-us_mouth_img" src="@/assets/mouth-pink.svg" alt="">
            </div>
            <div class="home_are-with-us_title">
                ARE YOU WITH US?
            </div>
            <div class="home_are-with-us_text">
                Unite for Her is a rallying cry for creative industries and marketing professionals to make
                the
                world a
                better, safer space for all women.
                It’s an invitation for all of us to come together and do our part.
                <br><br>We’ve created a series of <a href="#thebriefs">
                    pin-point
                    briefs</a> that address the many widespread factors that contribute to male
                violence
                against
                women. We’ve gathered the insights, as well as further reading and resources to inspire you.<br><br>
            </div>
            <div class="home_are-with-us_title">
                THE ASK
            </div>
            <div class="home_are-with-us_text">
                We’re asking you to commit to taking on a brief which you feel your brand or your client has
                the
                greatest
                opportunity to tackle. You don’t need our permission, just the creative ambition and energy to make it happen. If you want more information, some help with first thoughts, or just to share what you’ve done – get in touch.
            </div>
            <!-- <div class="button-join-us_are-with-us">
            <div class="join-us-now_black" @mouseover="hover1 = true" @mouseleave="hover1 = false">
                <div class="join-us-now_home-text">
                    JOIN US NOW
                </div>
                <div class="join-us-now_home-hand">
                    <img :src="imageSrc1">
                </div>
            </div>
        </div> -->
        </section>
    </div>
</template>
<style>
.slide2 {
    height: 100%;
}
</style>

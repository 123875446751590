<template>
    <section v-bind:class="this.$route.name" class="header">
        <div class="logo-header">
            <router-link to="/">
                <img class="logo-header-img" src="@/assets/logo_horizontal.svg" />
            </router-link>
        </div>
        <section class="containerHeader__headerImg-generic">
            <nav class="containerHeader__headerImg--navigationBar" v-if="isHome">
                <a class="navigationBtn" href="#thebriefs">THE BRIEFS</a>
                <a class="navigationBtn" href="#whois">WHO’S BEHIND UNITE FOR
                    HER?</a>
                <!-- <router-link class="navigationBtn" to="/contact">CONTACT</router-link> -->
            </nav>
            <!-- 
                
            </nav> -->
            <nav class="containerHeader__headerImg--navigationBar" v-if="!isHome">
                <a class="navigationBtn" href="/#thebriefs">THE BRIEFS</a>
                <a class="navigationBtn" href="/#whois">WHO’S BEHIND UNITE FOR
                    HER?</a>
            </nav>
        </section>
        <div class="home-menu-mobile">
            <img id="hamburger" @click="$emit('isActive', true)" src="@/assets/icon.svg" alt="">
        </div>
    </section>
</template>

<style>
@media (max-width: 450px) {
    .containerHeader__headerImg-generic .containerHeader__headerImg--navigationBar {
        display: none !important;
    }
}

.logo-header {
    margin-left: 35px;
}

.logo-header-imgMobile {
    display: none;
}

.header {
    width: 100vw;
    display: flex;
    background-color: #000000;
    font-family: CircularXX-Black;
    align-items: center;
    height: 115px;
    position: fixed;
    z-index: 99999 !important;
}

.header-logo {
    padding-left: 122px;
}

.header-logo_img {
    height: 34.97px;
    width: 26.39px;
}

nav.containerHeader__headerImg--navigationBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

a.navigationBtn {
    color: #FFFFFF;
    transform: scale(0.75);
}

section.containerHeader__headerImg-generic {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    font-size: 20px;
}

section.containerHeader__headerImg-generic .containerHeader__headerImg--navigationBar {
    margin-right: 35px;
}

.navigationBtn.navigationBtnBordered {
    box-sizing: border-box;
    height: 43px;
    width: 179px;
    border: 1px solid #FFFFFF;
    border-radius: 21.5px;
}

a#btnSuscribe {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-hand {
    height: 554px;
    width: 177px;
}

.logo-header-imgDesktop,
logo-header-imgMobile {
    width: 151px;
    height: 34px;
}
</style>

<script>
import { computed } from '@vue/reactivity'
import { useRoute, useRouter } from 'vue-router'

export default {
    name: "genericHeader",
    setup() {
        const route = useRoute()
        const router = useRouter()
        const isHome = computed(() => {
            return route.name == "home" ? true : false
        })
        function gotobriefs() {
            router.push({ name: "slider", hash: "#briefs" })
        }
        return {
            isHome,
            gotobriefs
        }
    },
    data() {
        return {
            isActive: false,
        }

    },
    methods: {
        active() {
            this.isActive = true
        },
        hideMenu() {
            this.isActive = false
        },

    }
}
</script>
<template>
    <div class="navSideBar">
        <div class="content-icon">
            <img class="icon-close" @click="$emit('isActive', false)" src="@/assets/icon_close.svg" alt="">
        </div>
        <div class="navBarContent">
            <div class="navSideBar-arm">
                <img src="@/assets/logo_withe_arm.svg" alt="">
            </div>
            <nav class="navLinks">
                <div>
                    <a href="/#thebriefs" @click="$emit('isActive', false)"  class="navigationBtnFooter">
                        THE BRIEFS</a>
                </div>
                <div>
                    <a href="/#whois" @click="$emit('isActive', false)" class="navigationBtnFooter">WHO’S
                        BEHIND<pre>UNITE FOR HER?</pre></a>
                </div>
            </nav>
            <nav class="navFooter">
                <div>
                    <a href="https://www.grey.com/legal">TERMS OF USE</a>
                </div>
                <div>
                    <router-link @click="$emit('isActive', false)" to="/cookie" class="navigationBtnFooter">COOKIES
                    </router-link>
                </div>
            </nav>
        </div>
    </div>
</template>

<style>
.navFooter div > a {
    color: #000000;
}
.navLinks div:not(:last-child) {
    padding-bottom: 20px;
}

.navFooter div:not(:last-child) {
    padding-bottom: 9px;
}

a.navigationBtnFooter pre {
    font-family: CircularXX-Black;
}

.navSideBar {
    background-color: #FFFFFF;
    height: 100vh;
    width: 100vw;
    position: fixed !important;
    top: 0;
    bottom: 0;
    overflow-y: scroll;
    z-index: 99999;

}

.content-icon {
    padding-top: 20px;
    text-align: right;
    padding-right: 22px;
}

.navLinks {
    padding-top: 54px;
    font-family: CircularXX-Black;
    font-size: 24px;
    color: #000000;
    padding-right: 54px;
}

.navFooter {
    padding-top: 48px;
    font-family: CircularXX-Medium;
    font-size: 18px;
}

.navBarContent {
    padding-left: 29px;
}
</style>
<script>
export default {
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        hideMenu: function () {
            this.isActive = true
        }
    }
}
</script>
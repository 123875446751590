<template>
                <firstSliderVue />
           
                <AreYouWithUs />
            
                <div class="slide3" id="thebriefs">
                    <ProvocationView />
                </div>
           
                <div class="slide4">
                    <UniteForHerView />
                    <Footer />
                </div>
</template>

<script>
import ProvocationView from "@/components/provocations/provocationsComponent.vue"
import UniteForHerView from "@/components/uniteforher/uniteForherComponent.vue"
import Footer from "@/components/genericFooter/genericFooter.vue"
import AreYouWithUs from "@/components/areYouWithUs/areYouWithUs.vue"
import firstSliderVue from "../firstSlider/firstSlider.vue"

import "@/css/style.css"
// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

import "swiper/css/scrollbar";

import "swiper/css/hash-navigation";

import "swiper/css/mousewheel";


// import required modules
export default {
    data: () => ({
        show: true,
        windowWidth: window.innerWidth
    }),
    components: {
        firstSliderVue,
        ProvocationView,
        UniteForHerView,
        Footer,
        AreYouWithUs
    },
    mounted() {
        window.onresize = () => {
            this.windowWidth = window.innerWidth
        }
    },
    methods: {
        showComponent(value) {
            console.log(value)
            this.show = value;
        }
    }
};

</script>
<style>
@media (max-width: 450px) {
    .slide1, .slide2 {
        height: auto !important;
    }

    .slide2 {
        padding-bottom: 40px;
    }

    .container_uniteforher-title {
        text-align: center;
        padding-bottom: 15px;
    }
}

@media (min-width: 750px) {

    .logo-header,
    .home-uniteforher-slider,
    .home_are-with-us,
    .provocations,
    .container_uniteforher-mouth,
    .container_uniteforher-title,
    .container_uniteforher-content,
    .footer-content_nav,
    .footer-content_tems,
    .footer-content_arm,
    span.footer-copyright {
        transform: scale(0.75);
    }
}

.areyou {
    height: 100% !important;
}

.slide1 {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(223deg,#2c2c2c,#000);
}

.slide2 {
    display: grid;
    align-items: center;
    justify-content: center;
    background: linear-gradient(223deg, rgb(239 239 239) 0%, rgb(255 255 255) 100%);
}

.home_are-with-us_mouth_slider {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
}



@media (max-width: 450px) {

    .container_uniteforher-mouth {
        padding-top: 70px !important;
    }

    

    .slide3 {
        padding-top: 60px;
    }

    .slide2,
    .home-uniteforher_arm {
        margin: 0 auto;
        padding-top: 20px !important;
    }

    #areyou {
        padding-top: 100px !important;
    }

    .slide3 .content.provocations {
        padding-bottom: 100px !important;
    }

    .swiper-pagination-vertical.swiper-pagination-bullets,
    .swiper-vertical>.swiper-pagination-bullets {
        display: none !important;
    }

    .home-uniteforher-slider {
        grid-template-columns: auto !important;
        margin: auto;
        height: auto !important;
    }

    .home-uniteforher-slider {
        flex-direction: column;
    }

    .content-home_uniteforher-slider .home-uniteforher-slider img.home-uniteforher_arm {
        height: 220px !important;
    }
}

@media (min-width: 1300px) and (min-height: 900px) {
    .content-home_uniteforher-slider {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
    }
}

.ps__thumb-y {
    background-color: #000 !important;
}

.ps {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}

.swiper-pagination-vertical.swiper-pagination-bullets,
.swiper-vertical>.swiper-pagination-bullets {
    margin-right: 30px;
}

.swiper {
    width: 600px;
    height: 300px;
}

.slide3 {
    background: linear-gradient(193deg, rgb(255 96 255) 0%, rgba(255, 0, 255, 1) 100%);
}

.slide4,
.slide5 {
    color: #FFFFFF;
    width: 100%;
    height: 100%;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: var(--swiper-pagination-bullet-vertical-gap, 12px) 0 !important;
}

.swiper-pagination-bullet {
    background: var(--swiper-pagination-bullet-inactive-color, #FFF) !important;
}

:root {
    --swiper-theme-color: #FFFFFF !important;
}

.content-home_uniteforher-slider .home-uniteforher-slider img.home-uniteforher_arm {
    width: 136px;
    height: 427px;
}

div#slide1-home {
    background: linear-gradient(223deg, rgb(44 44 44) 0%, rgb(0 0 0) 100%);
}

@media (min-width: 750px) {
    section.content-home_uniteforher-slider {
        padding-top: 115px;
    }
}


.home-uniteforher-slider {
    color: #FFFFFF;
    justify-content: center;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
}

.content-home_uniteforher-slider .home-uniteforher img {
    width: auto;
}

section.slider.header {
    background-color: #000000 !important;
}

#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #000000;

    /* Center slide text vertically */
    /* display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center; */
}
</style>
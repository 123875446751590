<template>
    <div class="content-cookie_page">
        <div class="content-cookie_page-title">
            TERMS OF USE
        </div>
        <div class="content-cookie_page-text">
            <div id="cookie-policy-description">A cookie is a small piece of data (text file) that a website – when visited by a user – asks your browser to store on your device in order to remember information about you, such as your language preference or login information. Those cookies are set by us and called first-party cookies. We also use third-party cookies – which are cookies from a domain different than the domain of the website you are visiting – for our advertising and marketing efforts. More specifically, we use cookies and other tracking technologies for the following purposes:</div>
            <section class="strictly-cookies">
        <h4 class="ot-sdk-cookie-policy-group">Strictly Necessary Cookies</h4>
        <p class="ot-sdk-cookie-policy-group-desc">These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. &nbsp; &nbsp;You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
        
        <table>
            <caption class="ot-scrn-rdr">Strictly Necessary Cookies</caption>
            <thead>
                <tr>
                    <th scope="col" class="ot-table-header ot-host">Cookie Subgroup</th>
                    
                    <th scope="col" class="ot-table-header ot-cookies">Cookies</th>
                    <th scope="col" class="ot-table-header ot-cookies-type">Cookies used</th>
                    <th scope="col" class="ot-table-header ot-life-span">Lifespan</th>
                </tr>
            </thead>
            <tbody><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span>www.uniteforher.co.uk</td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">
                    <a href="https://cookiepedia.co.uk/cookies/XSRF-TOKEN" rel="noopener" target="_blank" aria-label="XSRF-TOKEN Opens in a new Tab">
                        XSRF-TOKEN
                    </a>, 
                    <a href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed" rel="noopener" target="_blank" aria-label="OptanonAlertBoxClosed Opens in a new Tab">
                        OptanonAlertBoxClosed
                    </a></span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">First Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">A few seconds, 365 Days</span>
                    </td>
                </tr><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span>uniteforher.co.uk</td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">
                    <a href="https://cookiepedia.co.uk/cookies/OptanonConsent" rel="noopener" target="_blank" aria-label="OptanonConsent Opens in a new Tab">
                        OptanonConsent
                    </a>, 
                    <a href="https://cookiepedia.co.uk/cookies/__cfduid" rel="noopener" target="_blank" aria-label="__cfduid Opens in a new Tab">
                        __cfduid
                    </a></span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">First Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">365 Days, 30 Days</span>
                    </td>
                </tr><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span><a href="https://cookiepedia.co.uk/host/cdn.cookielaw.org" rel="noopener" target="_blank" aria-label="cdn.cookielaw.org Opens in a new Tab">
                        cdn.cookielaw.org
                        </a></td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">OptanonConsent, OptanonAlertBoxClosed</span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">Third Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">365 Days, 365 Days</span>
                    </td>
                </tr></tbody>
        </table>
    </section>
    <section class="performance-cookies">
        <h4 class="ot-sdk-cookie-policy-group">Performance Cookies</h4>
        <p class="ot-sdk-cookie-policy-group-desc">These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site. &nbsp; &nbsp;All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site, and will not be able to monitor its performance.</p>
        
        <table>
            <caption class="ot-scrn-rdr">Performance Cookies</caption>
            <thead>
                <tr>
                    <th scope="col" class="ot-table-header ot-host">Cookie Subgroup</th>
                    
                    <th scope="col" class="ot-table-header ot-cookies">Cookies</th>
                    <th scope="col" class="ot-table-header ot-cookies-type">Cookies used</th>
                    <th scope="col" class="ot-table-header ot-life-span">Lifespan</th>
                </tr>
            </thead>
            <tbody><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span>uniteforher.co.uk</td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">
                    <a href="https://cookiepedia.co.uk/cookies/_ga" rel="noopener" target="_blank" aria-label="_ga Opens in a new Tab">
                        _ga
                    </a>, 
                    <a href="https://cookiepedia.co.uk/cookies/_gid" rel="noopener" target="_blank" aria-label="_gid Opens in a new Tab">
                        _gid
                    </a></span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">First Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">730 Days, 1 Day</span>
                    </td>
                </tr></tbody>
        </table>
    </section>
    <section class="functional-cookies">
        <h4 class="ot-sdk-cookie-policy-group">Functional Cookies</h4>
        <p class="ot-sdk-cookie-policy-group-desc">These cookies enable the website to provide enhanced functionality and personalisation. They may be set by us or by third party providers whose services we have added to our pages. &nbsp; &nbsp;If you do not allow these cookies then some or all of these services may not function properly.</p>
        
        <table>
            <caption class="ot-scrn-rdr">Functional Cookies</caption>
            <thead>
                <tr>
                    <th scope="col" class="ot-table-header ot-host">Cookie Subgroup</th>
                    
                    <th scope="col" class="ot-table-header ot-cookies">Cookies</th>
                    <th scope="col" class="ot-table-header ot-cookies-type">Cookies used</th>
                    <th scope="col" class="ot-table-header ot-life-span">Lifespan</th>
                </tr>
            </thead>
            <tbody><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span>www.uniteforher.co.uk</td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">
                    <a href="https://cookiepedia.co.uk/cookies/_gd#############" rel="noopener" target="_blank" aria-label="_gd############# Opens in a new Tab">
                        _gd#############
                    </a></span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">First Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">730 Days</span>
                    </td>
                </tr><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span><a href="https://cookiepedia.co.uk/host/vimeo.com" rel="noopener" target="_blank" aria-label="vimeo.com Opens in a new Tab">
                        vimeo.com
                        </a></td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">vuid, __cf_bm</span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">Third Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">730 Days, A few seconds</span>
                    </td>
                </tr></tbody>
        </table>
    </section>
    <section class="targeting-cookies">
        <h4 class="ot-sdk-cookie-policy-group">Targeting Cookies</h4>
        <p class="ot-sdk-cookie-policy-group-desc">These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. &nbsp; &nbsp;They do not store directly personal information, but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.</p>
        
        <table>
            <caption class="ot-scrn-rdr">Targeting Cookies</caption>
            <thead>
                <tr>
                    <th scope="col" class="ot-table-header ot-host">Cookie Subgroup</th>
                    
                    <th scope="col" class="ot-table-header ot-cookies">Cookies</th>
                    <th scope="col" class="ot-table-header ot-cookies-type">Cookies used</th>
                    <th scope="col" class="ot-table-header ot-life-span">Lifespan</th>
                </tr>
            </thead>
            <tbody><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span>uniteforher.co.uk</td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">
                    <a href="https://cookiepedia.co.uk/cookies/_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx" rel="noopener" target="_blank" aria-label="_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx Opens in a new Tab">
                        _gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx
                    </a></span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">First Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">A few seconds</span>
                    </td>
                </tr><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span><a href="https://cookiepedia.co.uk/host/6sc.co" rel="noopener" target="_blank" aria-label="6sc.co Opens in a new Tab">
                        6sc.co
                        </a></td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">6suuid</span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">Third Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">730 Days</span>
                    </td>
                </tr><tr>
                    <td class="ot-host-td" data-label="Cookie Subgroup"><span class="ot-mobile-border"></span><a href="https://cookiepedia.co.uk/host/j.6sc.co" rel="noopener" target="_blank" aria-label="j.6sc.co Opens in a new Tab">
                        j.6sc.co
                        </a></td>
                    
                    <td class="ot-cookies-td" data-label="Cookies">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-td-content">_an_uid, _gd#############</span>
                    </td>
                    <td class="ot-cookies-type" data-label="Cookies used">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-cookies-type-td-content">Third Party</span>
                    </td>
                    <td class="ot-life-span-td" data-label="Lifespan">
                        <span class="ot-mobile-border"></span>
                        <span class="ot-life-span-td-content">7 Days, 730 Days</span>
                    </td>
                </tr></tbody>
        </table>
    </section>
        </div>
    </div>
    <Footer />
</template>
<style>
@media (max-width: 450px) {
    .content-cookie_page-text {

        padding-top: 50px !important;
        width: 100% !important;
    }

    .content-cookie_page {
        padding-left: 22px;
        padding-right: 22px;
    }

    section.terms-of-use.header {
        background-color: #000000 !important;
    }

}

p.ot-sdk-cookie-policy-group-desc {
    font-size: .9em;
}
    
.content-cookie_page-title {
    padding-top: 140px;
    color: #000000;
    font-family: CircularXX-Black;
    font-size: 48px;
    text-align: center;
    padding-bottom: 65px;
}

.content-cookie_page {
    background-color: #F2F2F2;
}

.content-cookie_page {
    padding-bottom: 139px;
}

.content-cookie_page-text {
    font-family: DMSans-Regular;
    font-size: 18px;
    width: 860px;
    margin: 0 auto;
}
table {
    border-collapse: inherit;
    margin: auto;
    border: 1px solid #d7d7d7;
    border-radius: 5px;
    border-spacing: initial;
    width: 100%;
    overflow: hidden;
    margin-top: 20px;
}
table th {
    background-color: #F8F8F8;
}
.ot-scrn-rdr {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
#cookie-policy-description {
    margin-bottom: 1em;
    font-size: .9em !important;
}

td {
    font-size: .9em;
    padding: 12px 15px;
    text-align: left;
}

.functional-cookies, .performance-cookies, .targeting-cookies, .strictly-cookies {
    padding-top: 20px;
}
</style>
<script setup>
import Footer from "@/components/genericFooter/genericFooter.vue"
const gdprScript = document.createElement("script");
gdprScript.setAttribute(
    "src",
    "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
);
gdprScript.setAttribute("data-domain-script", "83f1beb7-bdc5-481e-97b4-a2ab67d91a77")
document.head.appendChild(gdprScript);
</script>
<template>
  <div class="content provocations">
    <div class="provocations_title">
      THE BRIEFS
    </div>
    <div class="provocations_section">
      <div v-for="(result, index) in results" :key="index" class="provocations_row">
        <div class="brief-group">
          <router-link class="tackle-brief" :to="{ name: 'detailprovocations', params: { id: result.id } }">
            <div class="provocations_row-mouth">
              <img v-bind:src="result.acf.mouth" alt="">
            </div>
            <div class="provocations_row-title">
              <span v-html="result.title.rendered">
              </span>
            </div>
          </router-link>
        </div>
        <div class="provocations_row_link">
          <router-link class="tackle-brief" :to="{ name: 'detailprovocations', params: { id: result.id } }">TACKLE THIS
            BRIEF</router-link>
        </div>
      </div>
    </div>
    <div class="provocations_section-message">
      <span class="brief-contact">Don’t see a brief to fit your brand, but want to help? Get in contact
        <a class="mailto-briefs" href="mailto:info@uniteforher.co.uk">
          info@uniteforher.co.uk
        </a>
        <br>
      </span>
      <span class="legal-collecting">
        By sending us an email to: info@unitforher.co.uk you agree to us collecting your name and email address for the
        purposes solely of communicating with you about this campaign. No third parties will have access to your data.
        Our
        privacy policy is <a style="color:white" href="https://www.grey.com/legal">here</a>. You can exercise your data rights at any time by contacting: privacy@grey.com
      </span>

    </div>
    <!-- <div class="provocation-join-us">
      <div class="join-us-now_provocations" @mouseover="provocationSignUpHover = true"
        @mouseleave="provocationSignUpHover = false">
        <div class="join-us-now_provocations-text">
          SIGN-UP
        <div class="join-us-now_provocations-hand">
          <img class="manito" :src="provocationSignUp">
        </div>
      </div>
    </div> -->
  </div>
  <router-view></router-view>
</template>
<script>
import axios from "axios";
import SETTINGS from "../../../settings";
var URL = process.env.NODE_ENV == 'development' ? SETTINGS.LOCAL_URL_BASE : SETTINGS.PRD_URL_BASE
export default {
  data: () => ({
    results: null,
    provocationSignUpHover: null
  }),
  computed: {
    provocationSignUp: function () {
      return this.provocationSignUpHover ? URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlack.svg' : URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlanco.svg';
    }
  },
  methods: {
    async getBriefsApi() {
      await axios.get(URL + SETTINGS.API_BASE_PATH + "detailprovocations?per_page=100").then(results => {
        console.log(results)
        this.results = results.data;
      })
    }
  },
  mounted() {
    this.getBriefsApi()
  }
};
</script>

<style>
@media (max-width: 800px) and (min-width: 300px) {
  .provocations_section-message {
    flex-direction: column !important;
    text-align: center;
  }
}

@media (max-width: 420px) {
  .provocations_row {
    max-width: 170px !important;
    padding-top: 1px !important;
    width: 146px !important;
  }

  .provocations_section:not(:last-child) {
    padding-right: 5px;
  }
  .provocations_row-mouth img {
    width: 56px !important;
    height: 75px !important;
  }

  .provocations_row-title {
    font-size: 15px !important;
  }

  .brief-group {
    height: 209px !important;
  }

  .provocations_row-mouth {
    padding-bottom: 10px !important;
  }
}

@media (max-width: 620px) {
  .provocations_section {
    grid-template-columns: auto auto !important;
  }
}

@media (max-width: 428px) {
  .provocations_row_link {
    font-size: 12px !important;
  }

  .footer-content_arm-left {
    width: 100% !important;
    margin-left: 0 !important;
  }
}


@media (max-width: 450px) and (min-width: 421px) {
  .provocations_section-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 450px) {
  .provocations_row-mouth {
    padding-top: 0% !important;
    align-items: center;
  }
}

.brief-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 270px;  
}

.provocations_section-message {
  padding-top: 31px;
  color: #000000;
  font-family: DMSans-Regular;
  font-size: 14px;
  display: flex;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  flex-direction: column;
  padding-left: 22px;
  padding-right: 22px;
  font-style: italic;
}

.mailto-briefs {
  color: #FFFFFF;
}

section.briefs.header {
  background-color: #000000 !important;
}

.provocations .provocations_title {
  font-family: CircularXX-Black;
  font-size: 30px;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
}

.provocations_section {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  row-gap: 14px;
  column-gap: 14px;
}

.provocations_row {
  background: linear-gradient(223deg, rgb(44 44 44) 0%, rgb(0 0 0) 100%);
  color: #FFFFFF;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 14px;
  max-width: 250px;
}

.provocations_row-mouth {
  padding-bottom: 18px;
  height: 125px;
  display: flex;
  justify-content: center;
}

.provocations_row-mouth img {
  width: 75px;
  height: 100px;
}

.provocations_row-title {
  font-size: 20px;
  text-align: center;
  font-family: 'CircularXX-Thin';
  padding-left: 10px;
  padding-right: 10px;
  height: 80px;
}

.provocations_row_link {
  font-size: 16px;
  background-color: #000000;
  border-top: solid 1px #FF00FF;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
  text-align: center;
  font-family: 'CircularXX-Medium';
}

.tackle-brief {
  color: #FFFFFF;
}

.join-us-now_provocations {
  color: #FFFFFF;
  border-radius: 15px;
  background-color: #000000;
  width: 384px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-family: CircularXX-Black;
  font-size: 24px;
}

.join-us-now_provocations-hand {
  width: 30px;
}

.provocation-join-us {
  padding-top: 51px;
  display: flex;
  justify-content: center;
}

.join-us-now_provocations:hover {
  background-color: #FFFFFF;
  color: #000000;
}

.provocations .colors-line {
  margin-top: 143px;
}
</style>

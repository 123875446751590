<template>
    <div class="content-cookie_page">
        <div class="content-cookie_page-title">
            COOKIES
        </div>
        <!-- OneTrust Cookies List start -->
        <div id="ot-sdk-cookie-policy"></div>
        <!-- OneTrust Cookies List end -->
    </div>
    <Footer />
</template>
<style>
@media (max-width: 450px) {
    .content-cookie_page {
        padding-top: 115px;
    }

    .content-cookie_page-text {
        padding-top: 50px !important;
        width: 100% !important;
    }

    .content-cookie_page {
        padding-left: 22px !important;
        padding-right: 22px !important;
    }

    .header {
        background-color: #000000 !important;
    }

    section.cookie.header {
        background-color: #000000 !important;
    }
}

.content-cookie_page-title {
    padding-top: 59px;
    color: #000000;
    font-family: CircularXX-Black;
    font-size: 48px;
    text-align: center;
    padding-bottom: 65px;
}

.content-cookie_page {
    background-color: #F2F2F2;
}

.content-cookie_page {
    padding-bottom: 139px;
}

.content-cookie_page-text {
    font-family: DMSans-Bold;
    font-size: 18px;
    width: 600px;
    margin: 0 auto;
}
</style>
<script setup>
import Footer from "@/components/genericFooter/genericFooter.vue"

</script>
<script>
export default {
    mounted() {
    if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
    } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
    }
}
}

</script>
<template>
    <section class="footer">
        <div class="footer-content">
            <div class="footer-content_arm-left">
                <div class="footer-content_arm">
                    <img src="@/assets/logo_withe_arm.svg" alt="">
                </div>
                <div class="footer-content_nav">
                    <span>
                        <router-link class="navigationBtnFooter" to="/">UNITE FOR HER </router-link>
                    </span>
                    <span>
                        <router-link class="navigationBtnFooter" to="/#thebriefs">THE BRIEFS</router-link>
                    </span>
                    <span>
                        <router-link class="navigationBtnFooter" to="/#whois">WHO’S BEHIND UNITE FOR HER?</router-link>
                    </span>
                    <span>
                        <!-- <router-link class="navigationBtnFooter" to="/contact">CONTACT</router-link> -->
                    </span>
                </div>
            </div>
            <div class="footer-content_tems">
                <span>
                    <a href="https://www.grey.com/legal">TERMS OF USE</a>
                </span>
                <span>
                    <a href="/cookie">COOKIES</a>
                </span>
                <button id="ot-sdk-btn"
                    class="ot-sdk-show-settings cookie containerCookieSettings__cookieSetings">COOKIES
                    SETTINGS</button>
                <div class="social_links">
                    <!-- <a href="https://www.facebook.com/GreyGroup/" target="_blank">
                        <img src="@/assets/facebook-01.svg" class="icon-socialmedia" />
                    </a> -->
                    <a href="https://www.instagram.com/Unite_For_Her" target="_blank">
                        <img src="@/assets/instagram-01.svg" class="icon-socialmedia" />
                    </a>
                    <a href="https://twitter.com/Unite_For_Her" target="_blank">
                        <img src="@/assets/twitter-01.svg" class="icon-socialmedia" />
                    </a>
                    <a href="https://www.linkedin.com/company/unite-for-her" target="_blank">
                        <img src="@/assets/linkeding-01.svg" class="icon-socialmedia" />
                    </a>
                </div>
            </div>

        </div>
        <!-- <div class="button-join-us_are-with-us_footer">
            <div class="join-us-now_black-footer">
                <div class="join-us-now_home-text">
                    JOIN US NOW
                </div>
                <div class="join-us-now_home-hand">
                    <img class="manito" src="@/assets/manitoJoinBlanco.svg">
                </div>
            </div>
        </div> -->
    </section>
    <div class="footer-content_tems_mobile">
        <span>
            <a href="https://www.grey.com/legal">TERMS OF USE</a>
        </span>
        <span>
            <router-link to="/cookie">COOKIES</router-link>
        </span>
        <button id="ot-sdk-btn" class="ot-sdk-show-settings cookie containerCookieSettings__cookieSetings">COOKIES
            SETTINGS</button>
        <div class="social_links">
            <!-- <a href="https://www.facebook.com/GreyGroup/" target="_blank">
                        <img src="@/assets/facebook-01.svg" class="icon-socialmedia" />
                    </a> -->
            <a href="https://www.instagram.com/Unite_For_Her" target="_blank">
                <img src="@/assets/instagram-01.svg" class="icon-socialmedia" />
            </a>
            <a href="https://twitter.com/Unite_For_Her" target="_blank">
                <img src="@/assets/twitter-01.svg" class="icon-socialmedia" />
            </a>
            <a href="https://www.linkedin.com/company/unite-for-her" target="_blank">
                <img src="@/assets/linkeding-01.svg" class="icon-socialmedia" />
            </a>
        </div>
    </div>
    <div class="content-footer_copyright">
        <span class="footer-copyright">© {{ new Date().getFullYear() }} Copyright Unite For Her</span>
    </div>

</template>

<style>
@media (max-width: 450px) {
    .footer-content {
        display: grid !important;
        grid-template-columns: auto auto !important;
    }

    .footer-content_arm {
        padding-right: 22px !important;
        padding-left: 22px !important;
    }

    .footer-content_tems {
        display: none !important;
    }

    .footer-content_tems_mobile {
        padding-top: 32px !important;
        display: flex !important;
        flex-direction: column !important;
        background-color: #F2F2F2 !important;
        font-family: CircularXX-Medium;
        padding-bottom: 100px;
        font-size: 18px;
        padding-left: 105px !important;
        text-align: left;
    }

    .footer-copyright {
        padding-left: 22px !important;
        background-color: #F2F2F2 !important;
    }

    .content-footer_copyright {
        padding-bottom: 29px !important;
        justify-content: flex-start !important;
    }

    .footer-content_tems_mobile span:not(:last-child) {
        padding-bottom: 9px !important;
    }

    button#ot-sdk-btn {
        font-size: 16px !important;
    }

    #ot-sdk-btn.ot-sdk-show-settings,
    #ot-sdk-btn.optanon-show-settings {
        text-align: left;
    }

}

.social_links a:not(:last-child) {
    padding-right: 15px;
}

.social_links {
    padding-top: 20px;
}

.icon-socialmedia {
    height: 25px;
}

#ot-sdk-btn.ot-sdk-show-settings,
#ot-sdk-btn.optanon-show-settings {
    font-family: 'CircularXX-Medium' !important;
}

.footer-content_tems button {
    padding-top: 9px !important;
}

button#ot-sdk-btn {
    border: none !important;
    font-family: 'CircularXX-Medium' !important;
    color: #000000 !important;
    font-size: 18px !important;
    padding: 0 !important;
    text-transform: uppercase !important;
    line-height: 2 !important;
    background-color: #F2F2F2 !important;
}

button#ot-sdk-btn:hover {
    background-color: #F2F2F2 !important;
}

.footer-content_arm-left {
    float: left;
    width: 50%;
    display: flex;
}

.footer-content_tems span a,
.footer-content_tems_mobile span a {
    color: #000000;
}

.navigationBtnFooter {
    color: #000000;
}

.footer-content_tems_mobile {
    display: none;
}

.content-footer_copyright {
    background-color: #F2F2F2 !important;
    display: flex;
    padding-top: 37px;
    padding-bottom: 52px;
    justify-content: center;
}

.footer {
    flex-direction: column;
    align-items: center;
    background-color: #F2F2F2;
}

.footer-content {
    display: flex;
    padding-top: 30px;
    padding-right: 35px;
    padding-left: 35px;
}

.footer-content_nav,
.footer-content_tems {
    display: flex;
    flex-direction: column;
}

.footer-content_arm {
    padding-right: 51px;
}

.footer-content_nav {
    font-family: CircularXX-Black;
    font-size: 18px;
    display: flex;
    justify-content: center;
    padding-right: 40px;
    text-align: left;
}

.footer-content_nav:not(:last-child) span {
    padding-bottom: 10px;
}

.footer-content_tems {
    font-size: 18px;
    display: flex;
    justify-content: center;
    font-family: CircularXX-Medium;
    width: 50%;
    align-items: flex-end;
}

.footer-content_tems:not(:first-child) span {
    padding-top: 9px;
}

.footer-content_tems:only-child(3) span {
    padding-bottom: 9px;
}

.footer-copyright {
    font-family: DMSans-Regular;
    font-size: 14px;
    color: #000000;
}

.button-join-us_are-with-us_footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
</style>
<template>
  <HomeHeader @is-active="callback"></HomeHeader>
  <GenericHeader @is-active="callback"></GenericHeader>
  <NavComponent @is-active="callback" v-if="navVisible"></NavComponent>

  <router-view />
</template>
<script>
import HomeHeader from './components/homeHeader/homeHeader.vue'
import GenericHeader from './components/genericHeader/genericHeader.vue'
import NavComponent from './components/navComponent/navComponent.vue'
export default {
  name: 'App',
  data() {
    return {
      navVisible: false
    }
  },
  components: {
    HomeHeader,
    GenericHeader,
    NavComponent
  },
  methods: {
    callback: function (value) {
      this.navVisible = value
    }
  },
}
</script>

<style lang="scss" src="@/sass/main.scss">
</style>
<template>
    <div v-if="results" class="container-detailprovocations">

        <div class="container-detailprovocations-content_group">
            <div class="container-detailprovocations_mouth">
                <img class="home_are-with-us_mouth_img" :src="results.acf.mouth" alt="">
            </div>
            <div class="container-detailprovocations_content_group">
                <div v-html="results.title_cleaned" class="container-detailprovocations_title"></div>
                <div v-html="results.acf.description" class="container-detailprovocations_content">
                </div>
                <div class="container-detailprovocations-download">
                    <a v-if="results.acf.file" href="#" class="download-content_link"
                        @click.prevent="downloadItem(results.acf.file)">
                        <div class="container-detailprovocations_download" @mouseover="rotationDown"
                            @mouseleave="rotationNormal">
                            <div class="join-us-now_home-text">
                                {{ downloadText }}
                            </div>
                            <div class="join-us-now_home-hand">
                                <img class="manito" :src="imageSrc" :style="`transform: rotate(${rotation}deg);`">
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <Footer />
</template>
<script setup>
import Footer from "@/components/genericFooter/genericFooter.vue"
const gdprScript = document.createElement("script");
gdprScript.setAttribute(
    "src",
    "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
);
gdprScript.setAttribute("data-domain-script", "83f1beb7-bdc5-481e-97b4-a2ab67d91a77")
document.head.appendChild(gdprScript);
</script>
<script>
import axios from "axios";
import SETTINGS from "../../../settings";
var URL = process.env.NODE_ENV == 'development' ? SETTINGS.LOCAL_URL_BASE : SETTINGS.PRD_URL_BASE;
export default {
    data: () => ({
        results: null,
        rotation: 0,
        imageSrc: URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlanco.svg',
        downloadText: 'TACKLE THE BRIEF',
    }),
    methods: {
        rotationDown() {
            return this.rotation = 90, this.imageSrc = URL + '/wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlack.svg', this.downloadText = 'DOWNLOAD THE BRIEF'
        },
        rotationNormal() {
            return this.rotation = 0, this.imageSrc = URL + '/wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlanco.svg', this.downloadText = 'TACKLE THE BRIEF'
        },
        downloadItem(file) {
            if (file) {
                axios.get(file.url, { responseType: 'blob' })
                    .then(response => {
                        var fileUrl = window.URL.createObjectURL(new Blob([response.data]))
                        var fileLink = document.createElement('a')
                        fileLink.href = fileUrl
                        fileLink.setAttribute('download', file.filename)
                        document.body.appendChild(fileLink)
                        fileLink.click()
                        alert("Brief Downloaded Successfully")
                        this.rotationNormal()
                    }).catch(console.error)
            } else {
                console.log("No image loaded")
            }
        }
    },
    created() {
        let id = this.$route.params.id;
        axios.get(URL + SETTINGS.API_BASE_PATH + "detailprovocations/" + id).then((results) => {
            results.data['title_cleaned'] = results.data.title.rendered.replace("<br>", "")
            this.results = results.data
        })
    }
};
</script>
<style>
@media (max-width: 450px) {
    .container-detailprovocations-content_group {
        padding-left: 0px !important;
        padding-top: 20px;
    }

    .container-detailprovocations_content a {
        color: white;
    }

    .container-detailprovocations {
        flex-direction: column;
        padding-left: 20px !important;
        padding-right: 20px !important;
        height: 100% !important;
    }

    .download-content_link {
        display: flex;
        justify-content: center;
    }

    section.detailprovocations.header {
        background-color: #000000 !important;
    }

    .container-detailprovocations_content {
        padding-right: 22px !important;
        padding-left: 22px !important;
    }

    .container-detailprovocations_title {
        text-align: left !important;
        padding-left: 20px;
    }

    .container-detailprovocations_mouth {
        text-align: center !important;
        width: auto !important;
    }

    .container-detailprovocations-content_group {
        flex-direction: column;
    }
}

@media (min-width: 750px) {
    .container-detailprovocations_content_group {
        padding-left: 40px;
    }
}

.container-detailprovocations_content a {
    color: #FFFFFF;
}

.container-detailprovocations .container-detailprovocations_content p a {
    text-decoration: underline !important;
    cursor: pointer;
}

.container-detailprovocations {
    background: linear-gradient(223deg, rgb(44 44 44) 0%, rgb(0 0 0) 100%);
    display: flex;
    padding-top: 80px;
    padding-right: 80px;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.container-detailprovocations-content_group {
    display: flex;
}

.container-detailprovocations_title {
    color: #FFFFFF;
    align-items: center;
    font-family: CircularXX-Black;
    font-size: 38px;
}

.container-detailprovocations_content {
    color: #FFFFFF;
    font-family: DMSans-Regular;
    padding-top: 31px;
    margin: 0 auto;
    padding-top: 31px;
    padding-bottom: 37px;
    max-width: 800px;
}

.container-detailprovocations_download {
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    font-family: CircularXX-Black;
    font-size: 14px;
    color: #FFFFFF;
}

.container-detailprovocations_download:hover {
    background-color: #FF00FF;
    color: #000000;
}

.manito {
    transition: transform .3s ease-in-out;
}
</style>
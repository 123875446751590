<template>
    <section class="home-header" v-if="this.$route.name === 'home'">
        <section class="containerHeader__headerImg">
            <nav class="containerHeader__headerImg--navigationBar">
                <router-link class="navigationBtn" to="/#briefs">THE BRIEFSssss</router-link>
                <router-link class="navigationBtn" to="/#whois">WHO’S BEHIND UNITE FOR HER?</router-link>
                <!-- <router-link class="navigationBtn" to="/contact">CONTACT</router-link> -->
            </nav>
        </section>
        <div class="home-menu-mobile">
            <img id="hamburger" @click="$emit('isActive', true)" src="@/assets/icon.svg" alt="">
        </div>
    </section>
</template>

<style>
@media (max-width: 450px) {
    .home-header .containerHeader__headerImg .containerHeader__headerImg--navigationBar {
        display: none !important;
    }

    .home-header {
        position: fixed !important;
        align-items: center !important;
        justify-content: flex-end !important;
    }

    .home-menu-mobile {
        display: flex;
        align-items: center;
        padding-right: 22px;
    }

    .home-menu-mobile {
        display: block !important;
    }
}

.header .containerHeader__headerImg .containerHeader__headerImg--navigationBar .navigationBtn,
.home-header .containerHeader__headerImg .containerHeader__headerImg--navigationBar .navigationBtn {
    font-size: 24px;
}

nav.containerHeader__headerImg--navigationBar a:not(:last-child) {
    padding-right: 35px;
}

.home-menu-mobile {
    display: none;
}

.home-header {
    width: 100vw;
    height: 70px;
    display: flex;
    justify-content: flex-end;
    background-color: #000000;
    font-family: CircularXX-Black;
    position: fixed;
}

.header-logo {
    padding-left: 122px;
}

.header-logo_img {
    height: 34.97px;
    width: 26.39px;
}

nav.containerHeader__headerImg--navigationBar {
    display: flex;
    justify-content: center;
    align-items: center;
}

a.navigationBtn {
    color: #FFFFFF;
}

.navigationBtn:hover {
    color: #FF00FF;
}

section.containerHeader__headerImg {
    display: flex;
    align-items: center;
    margin-right: 105px;
}

.navigationBtn.navigationBtnBordered {
    box-sizing: border-box;
    height: 43px;
    width: 179px;
    border: 1px solid #FFFFFF;
    border-radius: 21.5px;
}

a#btnSuscribe {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-hand {
    height: 554px;
    width: 177px;
}
</style>
<script>
export default {
    name: "homeHeader",
}
</script>
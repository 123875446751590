import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import gtag from 'vue-gtag-next'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)
app.use(gtag, {
    property: {
        id: 'G-J7ES55XQ2G'
    }
})
app.use(PerfectScrollbar)
app.use(router)
app.mount('#app')

import { createRouter, createWebHistory } from "vue-router";
//import HomeView from "../components/home/homeView.vue"
//import Contact from "../components/contactPage/contactPage.vue"
import Provocations from "@/components/provocationsDetailPage/provocationsDetailPage.vue"
import Cookie from "@/components/cookiePage/cookiePage.vue"
import Privacy from "@/components/privacyPage/privacyPage.vue"
import Terms from "@/components/termsPage/termsPage.vue"
import SliderComponent from "@/components/sliderComponent/sliderComponent.vue"
import { trackRouter } from 'vue-gtag-next'
const routes = [
    {
        path: "/",
        name: "home",
        component: SliderComponent
    },
    {
        path: "/cookie",
        name: "cookie",
        component: Cookie
    },
    {
        path: "/privacy",
        name: "privacy",
        component: Privacy,
    },
    {
        path: "/terms-of-use",
        name: "terms-of-use",
        component: Terms,
    },
    { name: "detailprovocations", path: '/detailprovocations/:id', component: Provocations },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    /* scrollBehavior: function (to, from) {
        console.log(to.hash)
        console.log(from)
        if (to.hash) {
            let dinamycTop
            if (from.name == "home") {
                dinamycTop = 70
            } else if (from.name == undefined) {
                dinamycTop = 0
            } else {
                dinamycTop = 70
                console.log("aca")
            }
            /* dinamycTop = from.name == "home" || from.name == undefined ? 70 : 255; */
    /* console.log(dinamycTop)
    console.log(from.name)
    
    return { el: to.hash, top: dinamycTop } */
    /* setTimeout(() => {
        return { el: to.hash, top: dinamycTop }
    }, 30000)
    return { el: to.hash, top: dinamycTop }
} else {
    return { top: 0 }
}
} */
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition && !to.hash) 
        {
            return savedPosition;
        }
        
        if (undefined == from.name) {
            console.log("siii")

            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({
                        el: to.hash
                    });
                }, 1500);
            });
        } else {
            if (to.hash) {
                return { el: to.hash, top: 150 }
            } else {
                return { top: 0 }
            }
        }

    }
});
trackRouter(router)

export default router;
<template>
    <section class="container_uniteforher" id="whois">
        <div class="container_uniteforher-mouth">
            <img class="home_are-with-us_mouth_img" src="@/assets/mouth-pink.svg" alt="">
        </div>
        <div class="container_uniteforher-title">
            <span class="what-is">
                <h1>WHO’S BEHIND UNITE FOR HER?</h1>
            </span>
        </div>
        <div class="container_uniteforher-content">
            Every woman who’s walked home with her keys laced between her fingers. Every man who’s felt like he wished
            he could do more. Every person who felt disgusted every time they saw another news <br>story of violence
            against
            another woman.<br><br>

            This initiative might have come to life from inside the walls of Grey London. But it grew in the bellies of
            every angry person across our country who seethed – “There must be something we can do.”<br><br>

            <span class="her-fight">Her fight is Grey’s fight. And her fight is your fight too.</span> <br>

        </div>
        <!-- <div class="join-us-now_unitepage" @mouseover="hover = true" @mouseleave="hover = false">
            <div class="join-us-now_home-text">
                JOIN US NOW
            </div>
            <div class="join-us-now_home-hand">
                <img class="manito" :src="imageSrc">
            </div>
        </div> -->
    </section>
</template>
<script setup>
</script>
<script>
import SETTINGS from "../../../settings";
var URL = process.env.NODE_ENV == 'development' ? SETTINGS.LOCAL_URL_BASE : SETTINGS.PRD_URL_BASE
export default {
    data() {
        return {
            hover: false,
            hover1: false,
        }
    },
    computed: {
        imageSrc: function () {
            return this.hover ? URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlack.svg' : URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlanco.svg';
        },
        imageSrc1: function () {
            return this.hover1 ? URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlack.svg' : URL + 'wp-content/themes/uniteforher/FE/uniteforher/src/assets/manitoJoinBlanco.svg';
        }
    }
};
</script>


<style>
@media (max-width: 450px) {
    .container_uniteforher {
        padding-left: 22px;
        padding-right: 22px;
        height: auto !important;
    }

    .container_uniteforher-content {
        width: 100% !important;
    }

    .container_uniteforher-title {
        font-size: 28px !important;
    }

    .container_uniteforher-text {
        width: 100%;
    }



    .container_uniteforher-mouth {
        padding-top: 23px;
    }

}

.her-fight {
    font-style: italic;
    font-weight: bold;
}

.home_are-with-us_mouth_img {
    height: 134px !important;
}

section.uniteforher.header {
    background-color: #000000 !important;
}

.container_uniteforher {
    background: linear-gradient(223deg, rgb(44 44 44) 0%, rgb(0 0 0) 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70%;
}

.container_uniteforher-mouth {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_uniteforher-title {
    padding-top: 31px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: CircularXX-Black;
    font-size: 30px;
}

.container_uniteforher-content {
    color: #FFFFFF;
    font-family: DMSans-Regular;
    font-size: 18px;
    width: 600px;
    max-width: 576px;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
}

.join-us-now_unitepage {
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    width: 384px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    font-family: CircularXX-Black;
    font-size: 24px;
    color: #FFFFFF;
}

.join-us-now_unitepage:hover {
    background-color: #FF00FF;
}
</style>
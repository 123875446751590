<template>
    <div class="slide1" id="areyou">
        <section class="content-home_uniteforher-slider">
            <div class="home-uniteforher-slider">
                <img class="home-uniteforher_arm" src="@/assets/logo_arm_home.svg" alt="">
                <div class="home-uniteforher_content">
                    <div class="home-uniteforher_content_title">UNITE FOR HER</div>
                    <div class="home-uniteforher_content_text">
                        <span>
                            We’re living through an epidemic of male violence against women.<br> It’s an
                            epidemic
                            all
                            women know
                            well: trans women, older women, <br>young women, androgynous women, queer women
                            –
                            every
                            woman.<br><br>There are so many factors that have created this reality.<br>
                            The
                            problem is
                            overwhelmingly huge and complex.<br><br> None of us can solve this alone.
                            <br>But
                            together, we
                            can.<br><br>
                            <a href="#areyouwithus">Are you with us?</a>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>